<template>
  <VDialog v-model="visibility" max-width="500" content-class="cross__dialog" :persistent="changed" @click:outside="showConfirmDialog" :transition="$dialogTransition">
    <VCard>
      <VFadeTransition>
        <v-progress-linear v-model="linearQuery" :buffer-value="100" v-if="loadingCard" striped color="success"></v-progress-linear>
      </VFadeTransition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loadingCard">
          <VBtn fab small elevation="0" @click="showConfirmDialog">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </div>
      </v-slide-x-transition>
      <VCardTitle>
        {{ $t("table.edit_product_price") }} {{ product.name }}
      </VCardTitle>
      <VCardText class="pb-3">
        <div class="card-text__wrapper">
          <div v-if="!loadingCard">
            <VRow>
              <VCol>
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('table.init_price')"
                  v-model.number="copyProduct.price_init"
                  hide-details="auto"
                  :maxLength="9"
                  v-on:keypress="validationForPrice($event)"
                  @paste="validationForPrice($event)"
                />
              </VCol>
              <VCol>
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('table.sell_price')"
                  v-model.number="copyProduct.price_sell"
                  hide-details="auto"
                  :maxLength="9"
                  v-on:keypress="validationForPrice($event)"
                  @paste="validationForPrice($event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('table.min_price')"
                  v-model.number="copyProduct.price_min"
                  hide-details="auto"
                  :maxLength="9"
                  v-on:keypress="validationForPrice($event)"
                  @paste="validationForPrice($event)"
                />
              </VCol>
              <VCol>
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('table.max_price')"
                  v-model.number="copyProduct.price_max"
                  hide-details="auto"
                  :maxLength="9"
                  v-on:keypress="validationForPrice($event)"
                  @paste="validationForPrice($event)"
                />
              </VCol>
            </VRow>
          </div>
        </div>
        <VFadeTransition>
          <VSheet class="loader__wrapper" v-if="loadingCard">
            <div class="loader__inner">
              <v-progress-circular indeterminate color="success"></v-progress-circular>
            </div>
          </VSheet>
        </VFadeTransition>
      </VCardText>
      <VCardActions>
        <VRow no-gutters justify="space-between">
          <VCol cols="6" class="pr-1">
            <VBtn dark block class="br-10 text-transform-none" @click="showConfirmDialog">
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6" class="pl-1">
            <VBtn block class="success-bg text-white text-transform-none br-10" @click="onUpdate"
                  :disabled="loadingCard || !changed">{{ $t("btn.update") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog :visible="visibleConfirm" @close="visibleConfirm = false" @delete="onCancel"/>
  </VDialog>
</template>

<script>
import _ from "lodash";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import {validationForPrice} from "@/mixins/helpers";
import pricesMixin from "@/components/prices/pricesMixin";
import priceService from "@/services/request/prices/priceService";
import EventBus from '../../events/EventBus';
import notifications from "@/mixins/notifications";
import loader from "@/mixins/loader";

export default {
  mixins: [pricesMixin, validationMixin,notifications,loader],
  components: {ConfirmLeftDialog},
  props: {
    visible: {
      require: true,
    },
    product: {
      require: false,
    },
  },
  validations: {
    product: {
      name: {required},
      price_init: {required},
      price_sell: {required},
      price_min: {required},
      price_max: {required},
    },
  },
  data: () => ({
    loadingCard: true,
    linearQuery: 10,
    changed: false,
    inputProduct: {},
    copyProduct: {},
    visibleConfirm: false,
  }),
  mounted() {
    this.onMounted();
  },
  methods: {
    validationForPrice,
    onMounted() {
      this.loadingCard = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.product) {
        if (this.product[key] === null) {
          this.product[key] = "";
        }
      }
      this.copyProduct = {...this.product};
      this.inputProduct = {...this.product};
    },
    onCancel() {
      this.$emit("close");
      this.$v.$reset();
      this.copyProduct = {};
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onUpdate() {
      this.$v.$touch();
      try {
        // this.setLoading(true);
        const formData = new FormData();
        formData.append("uuid_product", this.copyProduct.uuid_product);
        formData.append("price_init", this.copyProduct.price_init ? this.copyProduct.price_init : 0);
        formData.append("price_sell", this.copyProduct.price_sell ? this.copyProduct.price_sell : 0);
        formData.append("price_min", this.copyProduct.price_min ? this.copyProduct.price_min : 0);
        formData.append("price_max", this.copyProduct.price_max ? this.copyProduct.price_max : 0);

        const result = await priceService.setPrice(formData);
        
        // EventBus.$emit("on-modified",result,'uuid_product');
        EventBus.$emit("on-close-dialog");
        EventBus.$emit("on-modified", result);
        this.$v.$reset();
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
        // this.setLoading(false);
        // this.loading = false;
      } catch (e) {
        // this.setLoading(false);
        console.log(e);
      }
    },
  },
  watch: {
    copyProduct: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyProduct, this.inputProduct);
      },
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card-text__wrapper {
  position: relative;
  min-height: 120px;
}

.loader {
  &__inner {
    width: 100%;
    height: 260px;
    z-index: 200;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 58px;
    left: 0;
  }
}
</style>
