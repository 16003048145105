import requestService from '../requestService';

const prefix = '/products/prices';

export default {
  async getPrices(payload) {
    const response = await requestService.get(`${prefix}/list`, payload);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async setPrice(payload) {
    const response = await requestService.post(`${prefix}/set`, payload);
    return response?.data?.object;
  },
  async resetPrice(uuid) {
    const response = await requestService.delete(`${prefix}/reset/${uuid}`);
    return response?.data?.object;
  },
};
